.App-notifications-content {

  background     : white;
  display        : flex;
  flex-direction : column;

  > a {

    padding       : 20px;
    font-size     : 18px;
    word-break    : break-all;
    border-bottom : 1px solid transparentize($gray, .5);

    &:last-child { border-bottom: 0; }
  }
}