.App-approve-main {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .App-approve-header {
    font-weight: 800;
    height: 55px;
    font-size: 16px;
    padding: 16px 24px;
    border-bottom: 1px solid #e8e8e8;
  }

  .App-approve-body {
    font-size: 16px;
    font-weight: 800;
    padding: 24px;
  }

  .App-approve-action {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    border-top: 1px solid #e8e8e8;

    button {
      height: 30px;
      width: 100px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      background: none;
      padding: 0 15px;
    }

    button:first-child {
      margin-right: 15px;
    }

    button:last-child {
      color: #ffffff;
      background-color: #038fde;
      border-color: #038fde;
      margin-left: 8px;
    }
  }

}