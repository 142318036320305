@import url(~react-table/react-table.css);
@import url(~react-big-calendar/lib/css/react-big-calendar.css);
@import url(~react-draft-wysiwyg/dist/react-draft-wysiwyg.css);
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?3koptb");
  src: url("../fonts/icomoon.eot?3koptb#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?3koptb") format("truetype"), url("../fonts/icomoon.woff?3koptb") format("woff"), url("../fonts/icomoon.svg?3koptb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow_back:before {
  content: "\e900"; }

.icon-arrow_down:before {
  content: "\e901"; }

.icon-arrow_next:before {
  content: "\e902"; }

.icon-arrow_top:before {
  content: "\e903"; }

.icon-attach:before {
  content: "\e904"; }

.icon-calendar:before {
  content: "\e905"; }

.icon-close:before {
  content: "\e906"; }

.icon-edit:before {
  content: "\e907"; }

.icon-eye:before {
  content: "\e908"; }

.icon-filter:before {
  content: "\e909"; }

.icon-help:before {
  content: "\e90a"; }

.icon-home:before {
  content: "\e90b"; }

.icon-item_1:before {
  content: "\e90c"; }

.icon-item_2:before {
  content: "\e90d"; }

.icon-mail:before {
  content: "\e90e"; }

.icon-menu:before {
  content: "\e90f"; }

.icon-notification:before {
  content: "\e910"; }

.icon-office-paper-printer:before {
  content: "\e911"; }

.icon-plus-addition-sign-circle:before {
  content: "\e912"; }

.icon-refresh-page-arrow:before {
  content: "\e913"; }

.icon-saved:before {
  content: "\e914"; }

.icon-search:before {
  content: "\e915"; }

.icon-settings:before {
  content: "\e916"; }

.icon-sort:before {
  content: "\e917"; }

.icon-star:before {
  content: "\e918"; }

.icon-upload:before {
  content: "\e919"; }

.icon-waste-can:before {
  content: "\e91a"; }

.icon-wish_list:before {
  content: "\e91b"; }

.icon-writing-notepad:before {
  content: "\e91c"; }

.icon-power:before {
  content: "\e9b5"; }

.icon-attachment:before {
  content: "\e9cd"; }

/* You can add HTML default tag styles */
@font-face {
  font-family: 'Regular';
  src: url("../fonts/Roboto-Regular.ttf"); }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Regular';
  outline: none; }

[class^="icon-rc-datepicker"], [class*=" icon-rc-datepicker"] {
  font-family: 'rc-datepicker' !important; }

.react-datepicker-input {
  border: 1px solid #cdcdcd !important;
  height: 45px !important;
  border-radius: 6px !important; }
  .react-datepicker-input > input {
    color: black !important;
    font-weight: 300 !important;
    padding-left: 3px !important; }

.ReactTags__selected {
  width: 100%;
  border: 1px solid #bcbcbc;
  padding: 10px 10px 0;
  display: flex;
  min-height: 40px;
  text-indent: 10px;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 6px; }
  .ReactTags__selected > span {
    float: left;
    border: 1px solid #D6D6D6;
    margin: 0 10px 10px 0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    word-break: break-all;
    border-radius: 6px; }
  .ReactTags__selected > .ReactTags__tagInput {
    flex: 1;
    min-width: 250px; }
    .ReactTags__selected > .ReactTags__tagInput > input {
      float: left;
      width: 100%;
      height: 20px;
      border: 0;
      padding: 0;
      min-height: auto;
      text-indent: 0;
      margin-bottom: 10px; }

[id^=contextMenu] {
  position: fixed !important;
  z-index: 999; }

.rbc-row-content {
  z-index: initial; }

.rbc-overlay {
  transform: translateY(-50px); }

html, body {
  font-size: 18px;
  color: #000000;
  background-color: #F6F6F6;
  overflow-y: hidden;
  min-width: 1024px; }

textarea, input, button, pre {
  font-size: 18px !important;
  font-family: 'Regular' !important;
  outline: none !important; }

input::placeholder, textarea::placeholder {
  color: #cccccc; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  appearance: none; }

input[type=file] {
  display: none; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset; }

i[class*=icon] {
  cursor: pointer; }

textarea {
  resize: none; }

a, button {
  cursor: pointer; }

a {
  text-decoration: none;
  cursor: pointer;
  color: #000; }

button {
  border: 0;
  background-color: transparent; }

h1, h2, h3 {
  letter-spacing: 1px; }

h4, h5, h6 {
  letter-spacing: 1px;
  font-weight: 100; }

h1 {
  font-size: 28px; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 22px; }

h5 {
  font-size: 20px; }

h6 {
  font-size: 18px; }

p {
  font-size: 16px; }

*::-webkit-scrollbar-track, *::-ms-autohiding-scrollbar {
  border-radius: 2px;
  background-color: #F5F5F5; }

*::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #F5F5F5; }

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: grey; }

.rbc-month-row {
  height: 80px;
  flex-basis: initial; }

.MuiFormLabel-focused-17 {
  color: #000000 !important; }

.MuiOutlinedInput-focused-24 fieldset {
  border-color: #000000 !important; }

.MuiButton-containedPrimary-137,
.MuiSwitch-bar-38 {
  background-color: #084698 !important; }

[class*="MuiCheckbox-checked"],
[class*="MuiRadio-checked"],
.MuiCheckbox-colorSecondary-124.MuiCheckbox-checked-120,
.MuiCheckbox-colorSecondary-101.MuiCheckbox-checked-97,
.MuiCheckbox-colorSecondary-98.MuiCheckbox-checked-94,
.MuiSwitch-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiSwitch-colorSecondary-36.MuiSwitch-checked-34 {
  color: #084698 !important; }
  [class*="MuiCheckbox-checked"]:hover,
  [class*="MuiRadio-checked"]:hover,
  .MuiCheckbox-colorSecondary-124.MuiCheckbox-checked-120:hover,
  .MuiCheckbox-colorSecondary-101.MuiCheckbox-checked-97:hover,
  .MuiCheckbox-colorSecondary-98.MuiCheckbox-checked-94:hover,
  .MuiSwitch-colorSecondary.Mui-checked:hover,
  .MuiCheckbox-colorSecondary.Mui-checked:hover,
  .MuiSwitch-colorSecondary-36.MuiSwitch-checked-34:hover {
    background-color: rgba(8, 70, 152, 0.2) !important; }

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(8, 70, 152, 0.2) !important; }

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: rgba(8, 70, 152, 0.5) !important; }

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important; }

.MuiPickersModal-dialogRootWider {
  min-width: 500px !important; }

.MuiPickersToolbarButton-toolbarBtn {
  margin-right: 20px !important; }

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClockPointer-pointer {
  background-color: #084698 !important; }

.MuiButton-textPrimary {
  color: #084698 !important; }

.MuiPickersClockPointer-thumb {
  border-color: #084698 !important; }

.rdw-editor-wrapper {
  width: 100%;
  min-height: 40px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  overflow-x: auto; }
  .rdw-editor-wrapper .rdw-editor-toolbar {
    padding: 5px 0;
    background-color: #ececec; }
    .rdw-editor-wrapper .rdw-editor-toolbar .rdw-option-wrapper,
    .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper {
      background: #ececec;
      border-color: #ececec; }
      .rdw-editor-wrapper .rdw-editor-toolbar .rdw-option-wrapper.rdw-option-active, .rdw-editor-wrapper .rdw-editor-toolbar .rdw-option-wrapper:hover,
      .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper.rdw-option-active,
      .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper:hover {
        border-color: #BFBDBD !important;
        box-shadow: none; }
  .rdw-editor-wrapper .DraftEditor-editorContainer {
    padding: 0 10px; }
  .rdw-editor-wrapper .rdw-text-align-wrapper,
  .rdw-editor-wrapper .rdw-inline-wrapper {
    margin-bottom: 0px; }

.G-flex {
  display: flex; }

.G-center {
  justify-content: center;
  align-items: center; }

.G-justify-center {
  justify-content: center; }

.G-justify-between {
  justify-content: space-between; }

.G-justify-around {
  justify-content: space-around; }

.G-justify-end {
  justify-content: flex-end; }

.G-align-content-start {
  align-content: flex-start; }

.G-align-center {
  align-items: center; }

.G-align-start {
  align-items: flex-start; }

.G-align-end {
  align-items: flex-end; }

.G-flex-column {
  flex-direction: column; }

.G-flex-wrap {
  flex-wrap: wrap; }

.G-m-1 {
  margin: 10px; }

.G-m-2 {
  margin: 20px; }

.G-m-3 {
  margin: 30px; }

.G-m-4 {
  margin: 40px; }

.G-m-5 {
  margin: 50px; }

.G-m-6 {
  margin: 60px; }

.G-mb-1 {
  margin-bottom: 10px; }

.G-mb-2 {
  margin-bottom: 20px; }

.G-mb-3 {
  margin-bottom: 30px; }

.G-mb-4 {
  margin-bottom: 40px; }

.G-mb-5 {
  margin-bottom: 50px; }

.G-mb-6 {
  margin-bottom: 60px; }

.G-mt-1 {
  margin-top: 10px; }

.G-mt-2 {
  margin-top: 20px; }

.G-mt-3 {
  margin-top: 30px; }

.G-mt-4 {
  margin-top: 40px; }

.G-mt-5 {
  margin-top: 50px; }

.G-mt-6 {
  margin-top: 60px; }

.G-mr-1 {
  margin-right: 10px; }

.G-mr-2 {
  margin-right: 20px; }

.G-mr-3 {
  margin-right: 30px; }

.G-mr-4 {
  margin-right: 40px; }

.G-mr-5 {
  margin-right: 50px; }

.G-mr-6 {
  margin-right: 60px; }

.G-ml-1 {
  margin-left: 10px; }

.G-ml-2 {
  margin-left: 20px; }

.G-ml-3 {
  margin-left: 30px; }

.G-ml-4 {
  margin-left: 40px; }

.G-ml-5 {
  margin-left: 50px; }

.G-ml-6 {
  margin-left: 60px; }

.G-p-1 {
  padding: 10px; }

.G-p-2 {
  padding: 20px; }

.G-p-3 {
  padding: 30px; }

.G-p-4 {
  padding: 40px; }

.G-p-5 {
  padding: 50px; }

.G-p-6 {
  padding: 60px; }

.G-pb-1 {
  padding-bottom: 10px; }

.G-pb-2 {
  padding-bottom: 20px; }

.G-pb-3 {
  padding-bottom: 30px; }

.G-pb-4 {
  padding-bottom: 40px; }

.G-pb-5 {
  padding-bottom: 50px; }

.G-pb-6 {
  padding-bottom: 60px; }

.G-pt-1 {
  padding-top: 10px; }

.G-pt-2 {
  padding-top: 20px; }

.G-pt-3 {
  padding-top: 30px; }

.G-pt-4 {
  padding-top: 40px; }

.G-pt-5 {
  padding-top: 50px; }

.G-pt-6 {
  padding-top: 60px; }

.G-pr-1 {
  padding-right: 10px; }

.G-pr-2 {
  padding-right: 20px; }

.G-pr-3 {
  padding-right: 30px; }

.G-pr-4 {
  padding-right: 40px; }

.G-pr-5 {
  padding-right: 50px; }

.G-pr-6 {
  padding-right: 60px; }

.G-pl-1 {
  padding-left: 10px; }

.G-pl-2 {
  padding-left: 20px; }

.G-pl-3 {
  padding-left: 30px; }

.G-pl-4 {
  padding-left: 40px; }

.G-pl-5 {
  padding-left: 50px; }

.G-pl-6 {
  padding-left: 60px; }

.G-w-10 {
  width: 10%; }

.G-w-20 {
  width: 20%; }

.G-w-25 {
  width: 25%; }

.G-w-30 {
  width: 30%; }

.G-w-35 {
  width: 35%; }

.G-w-40 {
  width: 40%; }

.G-w-45 {
  width: 45%; }

.G-w-50 {
  width: 50%; }

.G-w-55 {
  width: 55%; }

.G-w-60 {
  width: 60%; }

.G-w-65 {
  width: 65%; }

.G-w-70 {
  width: 70%; }

.G-w-75 {
  width: 75%; }

.G-w-80 {
  width: 80%; }

.G-w-f {
  width: 100%; }

.G-w-3 {
  width: calc(100 / 3); }

.G-details-title-label {
  font-weight: 700;
  margin-bottom: 10px; }

.G-details-upload-label {
  padding-left: 10px;
  margin-bottom: 10px; }
  .G-details-upload-label > b {
    font-weight: 700; }

.G-break-word {
  word-wrap: break-word; }

.G-capitalize {
  text-transform: capitalize; }

.G-error {
  border-color: #E6374C !important; }

.G-bold {
  font-weight: bold; }

.G-line {
  width: 100%;
  height: 1px;
  background-color: #ececec;
  margin: 5px 0 20px; }

.G-cursor {
  cursor: pointer; }

.G-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.G-btn {
  min-width: 140px !important;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  padding: 6px; }
  .G-btn.App-btn {
    width: 100%;
    background-color: #084698;
    border-color: #084698;
    color: #ffffff; }
  .G-btn.App-add-btn, .G-btn.App-save-btn {
    background-color: #F18407;
    color: #ffffff;
    border-color: #F18407; }
  .G-btn.App-add-btn-2 {
    color: #F18407;
    border: 0; }
  .G-btn.App-filter-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .G-btn.App-filter-btn i {
      font-size: 14px;
      margin-right: 10px; }

.G-text {
  color: rgba(0, 0, 0, 0.7); }

.G-page-active-title {
  color: #084698; }

.G-show {
  display: block; }

.G-hide {
  display: none; }
  .G-hide.G-show {
    display: block !important; }

.G-half-input-box .G-input-box {
  width: calc(50% - 10px); }

.G-input-box {
  width: 100%;
  margin-bottom: 20px; }
  .G-input-box .G-input-password-box, .G-input-box .G-input-delete-box {
    position: relative; }
    .G-input-box .G-input-password-box button, .G-input-box .G-input-password-box .icon-close, .G-input-box .G-input-delete-box button, .G-input-box .G-input-delete-box .icon-close {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #084698;
      font-size: 10px; }
    .G-input-box .G-input-password-box .icon-close, .G-input-box .G-input-delete-box .icon-close {
      font-size: 16px !important;
      color: black; }
      .G-input-box .G-input-password-box .icon-close:hover, .G-input-box .G-input-delete-box .icon-close:hover {
        color: #E6374C; }
  .G-input-box p span {
    color: #E6374C; }
  .G-input-box .G-input, .G-input-box .input, .G-input-box input, .G-input-box textarea, .G-input-box .App-drop-down-header {
    width: 100%;
    min-height: 40px;
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    text-indent: 10px;
    padding-right: 10px;
    overflow-x: auto; }
  .G-input-box .App-drop-down-component .App-drop-down-header {
    cursor: pointer;
    display: flex;
    align-items: center; }
  .G-input-box .App-drop-down-component .App-drop-down-body {
    top: 40px;
    width: 100%; }
    .G-input-box .App-drop-down-component .App-drop-down-body > .App-drop-down-list > div {
      cursor: pointer;
      padding: 5px 0; }
  .G-input-box textarea {
    padding: 10px;
    text-indent: 0; }

.G-input-box-desc > div {
  min-height: 700px; }

.menuItem:hover {
  font-weight: 800; }

.ql-editor p > span {
  color: black; }

.ql-container.ql-snow {
  min-height: 650px; }

.App-language-selector p {
  color: #000000;
  opacity: .6;
  cursor: pointer; }
  .App-language-selector p.active {
    opacity: 1; }

/* Upload photo */
.App-image-upload-component {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  margin-bottom: 20px; }
  .App-image-upload-component > input {
    display: none; }
  .App-image-upload-component.G-error .App-image-upload > label {
    border-color: #E6374C !important; }
  .App-image-upload-component.App-multi-image-upload {
    width: 100%; }
    .App-image-upload-component.App-multi-image-upload .App-image-upload {
      width: calc((100% - 40px) / 3);
      height: 250px; }
      .App-image-upload-component.App-multi-image-upload .App-image-upload:nth-child(3n + 1) {
        margin-right: 0 !important; }
      .App-image-upload-component.App-multi-image-upload .App-image-upload .App-image {
        height: 250px; }
      .App-image-upload-component.App-multi-image-upload .App-image-upload .App-uploaded-image {
        padding: 0; }
        .App-image-upload-component.App-multi-image-upload .App-image-upload .App-uploaded-image.image {
          height: 250px; }

.App-image-upload {
  width: 100%;
  height: 100%; }

.App-image-upload > label,
.App-uploaded-image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #3A3A3A;
  border-radius: 18px;
  cursor: pointer;
  background-color: #EBEBEB; }

.App-image-upload > label > i {
  font-size: 30px;
  margin-bottom: 10px;
  color: #707070; }

.App-image-upload > label > p {
  color: #707070;
  text-align: center; }
  .App-image-upload > label > p b {
    text-decoration: underline; }

.App-uploaded-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.App-uploaded-image > .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.App-uploaded-image > .opacity {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3); }

.App-uploaded-image > .opacity > h4 {
  color: #fff; }

.App-uploaded-image:hover > .opacity {
  opacity: 1; }

/* Upload photo end */
/* Upload file */
.App-file-upload-component .input {
  width: 100%;
  min-height: 40px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  text-indent: 10px;
  padding-right: 10px;
  overflow-x: auto; }

.App-file-upload-component .App-file-upload,
.App-file-upload-component label {
  position: relative; }
  .App-file-upload-component .App-file-upload .icon-close,
  .App-file-upload-component label .icon-close {
    position: absolute;
    right: 10px; }
    .App-file-upload-component .App-file-upload .icon-close:hover,
    .App-file-upload-component label .icon-close:hover {
      color: #E6374C; }
  .App-file-upload-component .App-file-upload p,
  .App-file-upload-component label p {
    width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

/* Upload file */
/* Modal */
.App-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; }

.App-modal-content {
  max-width: 90vw;
  max-height: 90vh;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  border-radius: 4px; }

/* Modal end */
/* Table start */
.App-table-image {
  width: 45px;
  height: 45px;
  border-radius: 4px; }

.App-table-toolbar {
  width: 100%; }

.App-table-toolbar-title span {
  color: rgba(0, 0, 0, 0.5); }

.App-table-toolbar-viewmode {
  font-size: 20px;
  margin-left: 10px;
  font-weight: 700; }
  .App-table-toolbar-viewmode.App-active-viewmode {
    color: #084698; }
  .App-table-toolbar-viewmode:first-of-type {
    margin-left: 20px; }

.App-table-status-item {
  display: flex;
  align-items: center; }
  .App-table-status-item span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px; }

/* Table end */
/* Date picker */
.App-date-picker.G-error .MuiInputBase-root {
  border-color: #E6374C; }

.App-date-picker .MuiInputBase-root {
  width: 100%;
  height: auto;
  min-height: 40px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  text-indent: 10px;
  padding: 0 10px 0 0;
  overflow: hidden; }
  .App-date-picker .MuiInputBase-root::after, .App-date-picker .MuiInputBase-root::before {
    display: none; }
  .App-date-picker .MuiInputBase-root input {
    height: 38px;
    min-height: 38px;
    border: none !important;
    padding: 0;
    cursor: pointer; }

/* Date picker end */
header {
  width: 100%;
  height: 70px;
  padding: 0 5%;
  background: linear-gradient(180deg, #000068 0%, #084698 100%);
  color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); }
  header .App-header {
    height: 70px; }
  header .App-header-notification {
    color: white;
    position: relative;
    font-size: 22px;
    margin-right: 30px; }
    header .App-header-notification > span {
      right: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      background: red;
      border-radius: 50%; }
  header .logo img {
    height: 45px; }
  header .left-block > div {
    position: relative; }
    header .left-block > div > .App-notifications-badge {
      top: -2px;
      right: -8px;
      width: 15px;
      height: 15px;
      padding-top: 1px;
      padding-left: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: red;
      border-radius: 50%;
      font-size: 12px; }
  header .left-block a {
    color: inherit;
    opacity: .7; }
    header .left-block a:hover, header .left-block a.active {
      opacity: 1; }

.App-notification-list {
  width: 500px;
  margin-right: 20px !important; }
  .App-notification-list > div {
    padding: 20px; }
  .App-notification-list .App-notification-header {
    border-bottom: 1px solid #ececec; }
  .App-notification-list .App-notification-footer {
    border-top: 1px solid #ececec; }
    .App-notification-list .App-notification-footer button:hover {
      color: #084698; }

.notification {
  background-color: #fff !important;
  border-top-color: #084698 !important;
  color: #000000 !important;
  padding: 15px !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75); }
  .notification .notification-dismiss {
    background-color: transparent !important;
    color: #000000 !important; }

.App-notification-item {
  background-color: #fff;
  color: #000000 !important; }
  .App-notification-item h6 {
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.App-drop-down-component {
  width: 100%;
  height: 100%;
  position: relative; }
  .App-drop-down-component.App-filter .App-drop-down-body {
    left: auto !important;
    right: 20px; }
  .App-drop-down-component .App-drop-down-header {
    height: 100%; }
  .App-drop-down-component .App-drop-down-body {
    position: absolute;
    top: 50px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    z-index: 500;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); }

.App-drop-down-list {
  min-width: 200px;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px; }
  .App-drop-down-list::-webkit-scrollbar, .App-drop-down-list::-webkit-scrollbar-track, .App-drop-down-list::-webkit-scrollbar-thumb {
    display: none; }
  .App-drop-down-list a {
    display: block; }
    .App-drop-down-list a p {
      display: block !important; }
    .App-drop-down-list a:last-child p {
      margin-bottom: 0; }
    .App-drop-down-list a.active p {
      font-weight: bold; }
  .App-drop-down-list > div.active p {
    font-weight: bold; }
  .App-drop-down-list p {
    height: 25px;
    margin-bottom: 8px;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center; }
  .App-drop-down-list > p {
    display: block; }

.App-sign-in-page {
  width: 100%;
  height: 100vh; }
  .App-sign-in-page .left-box {
    width: 100%;
    width: 100%;
    background: url("../images/background.jpg") no-repeat center/cover;
    display: flex;
    align-items: flex-end;
    position: relative; }
    .App-sign-in-page .left-box img {
      height: 70px;
      position: absolute;
      top: 10%;
      left: 10%; }
  .App-sign-in-page .right-box {
    width: 800px; }
    .App-sign-in-page .right-box .form {
      width: 100%;
      padding: 0 15%; }

.App-registration-page {
  width: 100%;
  height: 100vh; }
  .App-registration-page .left-box {
    width: 50%;
    height: 100%;
    background: url("../images/background.jpg") no-repeat center/cover;
    display: flex;
    align-items: flex-end;
    position: relative; }
    .App-registration-page .left-box img {
      height: 70px;
      position: absolute;
      top: 10%;
      left: 10%; }
  .App-registration-page .right-box {
    width: 50%;
    min-width: 800px;
    height: 100%;
    overflow-y: auto; }
    .App-registration-page .right-box .form {
      width: 100%;
      padding: 5% 10%; }

.App-choose-default-image {
  min-width: 300px;
  min-height: 250px;
  padding: 8%; }
  .App-choose-default-image .images div {
    width: 150px;
    height: 150px;
    border: 2px solid rgba(128, 128, 128, 0.3);
    border-radius: 6px;
    cursor: pointer; }
    .App-choose-default-image .images div.active {
      border-color: #084698; }

.App-invite-user {
  min-width: 400px;
  padding: 20px 30px; }
  .App-invite-user .App-add-btn {
    color: #F18407; }
  .App-invite-user .G-btn.App-btn {
    width: 200px; }
  .App-invite-user .App-invite-user-item {
    position: relative; }
    .App-invite-user .App-invite-user-item i {
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px; }
      .App-invite-user .App-invite-user-item i.G-cursor:hover {
        color: #E6374C; }

.App-user-item-start-end {
  width: 200px; }

.App-user-item {
  width: calc((100% - 400px) / 3); }

.App-user-item-table > div {
  border-bottom: 1px solid #e0e0e0; }

.App-modify-media {
  min-width: 400px;
  padding: 20px 30px; }
  .App-modify-media .App-add-btn {
    color: #F18407; }
  .App-modify-media .G-btn.App-btn {
    width: 200px; }
  .App-modify-media .App-modify-media-content {
    position: relative;
    flex-wrap: wrap; }

.App-media-items-view-content {
  display: flex;
  padding: 10px;
  flex-wrap: wrap; }

.App-media-list-item {
  flex: 1;
  margin: 10px;
  height: 300px;
  cursor: pointer;
  min-width: 300px; }

.App-media-details {
  width: calc(100vw - 150px);
  height: calc(100vh - 150px); }

.App-add-page-news-event .line {
  width: 100%;
  height: 5px;
  background-color: rgba(188, 188, 188, 0.5); }

.App-add-page-news-event .title {
  font-weight: bold; }

.App-add-page-news-event .block {
  padding: 20px; }

.App-add-page-news-event .App-manage-event-news-image {
  height: 350px; }
  .App-add-page-news-event .App-manage-event-news-image > div {
    height: 100%; }
  .App-add-page-news-event .App-manage-event-news-image .App-image-upload-component {
    width: 100%; }

.App-add-page-news-event .App-manage-event-news-info,
.App-add-page-news-event .App-manage-event-location-block {
  padding: 20px; }

.App-section-list {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 5px 0; }
  .App-section-list .App-section-item {
    width: 100%;
    min-height: 250px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 20px; }
    .App-section-list .App-section-item .header {
      width: 100%; }
    .App-section-list .App-section-item .content {
      min-height: 200px; }
      .App-section-list .App-section-item .content .G-bg-image {
        width: 30%; }
      .App-section-list .App-section-item .content .text {
        width: 65%; }

.App-radio-btn-box .G-flex {
  display: flex !important;
  flex-direction: row; }

.App-add-section {
  width: 50vw;
  min-width: 300px;
  padding: 20px; }
  .App-add-section.App-add-section-auto-width {
    width: auto; }
  .App-add-section .image-upload-box {
    width: 100%; }
    .App-add-section .image-upload-box .image-upload {
      width: 100%;
      height: 200px; }
      .App-add-section .image-upload-box .image-upload .App-image-upload-component {
        width: 100% !important; }
  .App-add-section .form {
    width: 100%;
    min-width: 500px; }

.App-page-calculator-box {
  width: 100%;
  height: 60px;
  margin: 20px 5%; }
  .App-page-calculator-box .App-page-calculator-header {
    display: flex;
    justify-content: space-between;
    max-width: 90vw; }
    .App-page-calculator-box .App-page-calculator-header .App-page-calculator-header-box {
      display: flex; }
      .App-page-calculator-box .App-page-calculator-header .App-page-calculator-header-box div {
        width: 500px; }
    .App-page-calculator-box .App-page-calculator-header .save {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      height: 40px;
      margin: 22px 0 20px 50px; }
    .App-page-calculator-box .App-page-calculator-header .disabled {
      background-color: #c2c2c2;
      color: #ffffff;
      border-color: #c2c2c2;
      height: 40px;
      margin: 22px 0 20px 50px;
      cursor: default; }
    .App-page-calculator-box .App-page-calculator-header .download {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 200px;
      height: 40px;
      margin: 22px 0 20px 150px; }
  .App-page-calculator-box .App-page-calculator-body {
    max-width: 90vw;
    max-height: 70vh;
    height: 70vh;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    padding: 5px 20px 25px 5px; }
  .App-page-calculator-box .App-calculator-body-row {
    display: flex;
    flex-direction: row; }
    .App-page-calculator-box .App-calculator-body-row .App-calculator-body-item {
      min-width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #ececec;
      overflow: hidden; }
    .App-page-calculator-box .App-calculator-body-row .main {
      border: none;
      font-weight: 800; }
    .App-page-calculator-box .App-calculator-body-row .short {
      min-width: 80px; }

.App-newspaper-add {
  width: 680px;
  height: 600px;
  padding: 20px; }
  .App-newspaper-add .App-newspaper-add-body {
    height: 470px;
    display: flex; }
    .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-image-container {
      width: 35%;
      padding-top: 20px; }
      .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-image-container .App-newspaper-add-body-image-box {
        height: 287px; }
    .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-main {
      width: 65%;
      padding: 15px 0; }
      .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-main .App-newspaper-add-date-number {
        width: 415px;
        display: flex;
        justify-content: space-between; }
        .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-main .App-newspaper-add-date-number div:first-child {
          width: 200px !important; }
      .App-newspaper-add .App-newspaper-add-body .App-newspaper-add-body-main .App-newspaper-add-activity {
        display: flex;
        width: 200px;
        height: 80px;
        justify-content: space-between;
        align-items: center; }
  .App-newspaper-add .App-newspaper-add-footer {
    display: flex;
    justify-content: flex-end; }
    .App-newspaper-add .App-newspaper-add-footer .disabled {
      background-color: #c2c2c2;
      color: #ffffff;
      border-color: #c2c2c2;
      cursor: default; }
    .App-newspaper-add .App-newspaper-add-footer .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 120px;
      height: 40px;
      border-radius: 6px; }

.App-page-favorite-box {
  width: 90vw;
  margin: 20px 5%; }
  .App-page-favorite-box .App-page-favorite-content {
    width: 100%;
    max-height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    overflow-y: auto; }
    .App-page-favorite-box .App-page-favorite-content .App-page-favorite-header {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 0 20px;
      border-bottom: 1px solid #e0e0e0; }
      .App-page-favorite-box .App-page-favorite-content .App-page-favorite-header > div {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.3125rem; }
    .App-page-favorite-box .App-page-favorite-content .App-page-favorite-item {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 0 20px;
      border-bottom: 1px solid #e0e0e0; }

.App-book-choose-categories {
  width: 500px;
  padding: 30px; }
  .App-book-choose-categories > div {
    padding: 10px 0;
    font-size: 18px;
    word-break: break-all; }
    .App-book-choose-categories > div > span {
      margin-left: 10px; }

.App-book-categories-input {
  word-break: break-all;
  text-indent: 0 !important;
  display: flex;
  align-items: center;
  padding-left: 10px; }

.App-manage-book-image {
  padding: 20px; }
  .App-manage-book-image .App-image-upload-component {
    max-height: 70%; }

.App-modify-book-category {
  display: flex;
  height: 250px;
  width: 500px; }
  .App-modify-book-category .App-modify-book-category-image {
    width: 40%; }
  .App-modify-book-category > div:last-child {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: flex-start; }

.App-programs {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  margin: auto; }
  .App-programs .App-programs-list {
    width: 30%;
    height: calc(100vh - 130px);
    padding: 60px 40px 0 5px;
    overflow-y: auto; }
    .App-programs .App-programs-list > div {
      padding: 25px;
      margin-top: 30px;
      display: flex;
      cursor: pointer;
      position: relative;
      flex-direction: column;
      background: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); }
      .App-programs .App-programs-list > div > .App-programs-actions {
        top: 25px;
        right: 25px;
        display: flex;
        position: absolute; }
        .App-programs .App-programs-list > div > .App-programs-actions > i {
          font-size: 20px;
          margin-left: 10px; }
      .App-programs .App-programs-list > div.App-chosen-program {
        border-left: 5px solid #084698;
        padding-left: 20px; }
      .App-programs .App-programs-list > div > p:first-child {
        padding-right: 10px;
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 19px; }
      .App-programs .App-programs-list > div > p {
        margin-top: 5px;
        word-wrap: break-word; }
        .App-programs .App-programs-list > div > p > i {
          margin-right: 13px; }
      .App-programs .App-programs-list > div > .user-count {
        color: #505050;
        border: 1px solid rgba(80, 80, 80, 0.5);
        float: right;
        padding: 0 5px;
        margin-top: 20px;
        font-weight: 300;
        border-radius: 4px;
        align-self: flex-end; }
        .App-programs .App-programs-list > div > .user-count > i {
          margin-right: 10px; }
  .App-programs .App-programs-info {
    width: 70%;
    background-color: #f6f6f6;
    padding: 20px; }
    .App-programs .App-programs-info .App-programs-breadcrumbs {
      display: flex; }
      .App-programs .App-programs-info .App-programs-breadcrumbs > div {
        font-size: 14px;
        margin-right: 10px; }
        .App-programs .App-programs-info .App-programs-breadcrumbs > div:last-child > span {
          cursor: default;
          color: #084698; }
        .App-programs .App-programs-info .App-programs-breadcrumbs > div > span {
          cursor: pointer;
          margin-right: 10px; }
    .App-programs .App-programs-info .App-program-tabs {
      padding: 25px 0;
      display: flex;
      align-items: center; }
      .App-programs .App-programs-info .App-program-tabs > h4 {
        cursor: pointer;
        margin-right: 40px;
        padding-bottom: 5px; }
        .App-programs .App-programs-info .App-program-tabs > h4.App-active-tab {
          color: #084698;
          border-bottom: 2px solid #084698; }
      .App-programs .App-programs-info .App-program-tabs .G-btn {
        margin-left: auto; }
      .App-programs .App-programs-info .App-program-tabs .icon-waste-can {
        margin-left: 20px;
        font-size: 22px; }
    .App-programs .App-programs-info .App-programs-content {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      background: white;
      max-height: calc(100vh - 280px);
      overflow-y: auto; }
    .App-programs .App-programs-info .App-programs-properties {
      padding: 25px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .App-programs .App-programs-info .App-programs-calendar {
      width: 100%;
      height: 800px;
      margin-left: 20px; }

.App-manager-info-main {
  width: 100%; }
  .App-manager-info-main.App-manager-info-main-full .App-manager-info-managers {
    width: 100% !important;
    margin-top: 60px !important;
    margin-bottom: 20px; }
  .App-manager-info-main.App-manager-info-main-full .App-manager-info-autocomplete {
    width: 100% !important; }
  .App-manager-info-main .App-manager-info {
    width: 50%;
    padding: 20px;
    position: relative; }
    .App-manager-info-main .App-manager-info.App-manager-info-full {
      width: 100%;
      padding: 0; }
    .App-manager-info-main .App-manager-info .G-input-box {
      width: 100%; }
      .App-manager-info-main .App-manager-info .G-input-box input {
        width: 100%; }
    .App-manager-info-main .App-manager-info .App-manager-info-autocomplete {
      width: calc(100% - 40px);
      margin-top: -15px;
      position: absolute;
      z-index: 100;
      background-color: #fff; }
      .App-manager-info-main .App-manager-info .App-manager-info-autocomplete ul {
        border: 1px solid #bcbcbc;
        border-radius: 6px;
        width: 100%;
        padding: 5px; }
  .App-manager-info-main .App-manager-info-managers {
    width: calc(100% - 40px);
    margin-top: -15px;
    z-index: 80; }
    .App-manager-info-main .App-manager-info-managers .App-manager-info-manager-item {
      width: 100%;
      padding: 5px; }
      .App-manager-info-main .App-manager-info-managers .App-manager-info-manager-item li {
        border-bottom: 1px solid #bcbcbc !important; }

#margin_top_40 {
  margin-top: -40px; }

.App-manager-info-autocomplete ul li,
.App-manager-info-manager-item li {
  list-style: none;
  width: 100%;
  min-height: 40px;
  text-indent: 10px;
  padding: 10px;
  font-size: 18px !important;
  font-family: 'Regular' !important;
  outline: none !important;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  position: relative; }
  .App-manager-info-autocomplete ul li .App-manager-info-autocomplete-image,
  .App-manager-info-manager-item li .App-manager-info-autocomplete-image {
    width: 35px;
    height: 35px;
    border-radius: 6px; }
  .App-manager-info-autocomplete ul li .App-manager-info-delete-icon,
  .App-manager-info-manager-item li .App-manager-info-delete-icon {
    position: absolute;
    top: 50%;
    right: -35px;
    font-size: 24px;
    transform: translateY(-50%); }
  .App-manager-info-autocomplete ul li .App-manager-info-autocomplete-texts,
  .App-manager-info-manager-item li .App-manager-info-autocomplete-texts {
    display: flex;
    flex-direction: column;
    align-content: center; }
    .App-manager-info-autocomplete ul li .App-manager-info-autocomplete-texts p,
    .App-manager-info-manager-item li .App-manager-info-autocomplete-texts p {
      font-size: 18px !important; }
    .App-manager-info-autocomplete ul li .App-manager-info-autocomplete-texts span,
    .App-manager-info-manager-item li .App-manager-info-autocomplete-texts span {
      font-size: 10px !important; }
  .App-manager-info-autocomplete ul li .App-manager-info-responsive-text,
  .App-manager-info-manager-item li .App-manager-info-responsive-text {
    display: flex;
    margin-left: auto;
    align-items: center; }
    .App-manager-info-autocomplete ul li .App-manager-info-responsive-text > span,
    .App-manager-info-manager-item li .App-manager-info-responsive-text > span {
      color: #FFC30B;
      font-size: 30px;
      margin-right: 10px;
      margin-bottom: 5px; }

.App-program-modal-add {
  width: 680px;
  padding: 20px; }
  .App-program-modal-add.App-program-activity-modal .App-manager-info-manager-item {
    width: calc(100% - 40px) !important; }
  .App-program-modal-add .App-program-modal-add-footer {
    display: flex;
    justify-content: flex-end; }
    .App-program-modal-add .App-program-modal-add-footer .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 120px;
      height: 40px;
      border-radius: 6px; }

.App-program-modal-delete {
  width: 400px;
  padding: 20px; }
  .App-program-modal-delete .App-program-modal-delete-body {
    margin: 10px 0 30px 0; }
  .App-program-modal-delete .App-program-modal-delete-footer {
    display: flex;
    justify-content: flex-end; }
    .App-program-modal-delete .App-program-modal-delete-footer button {
      width: 90px;
      height: 30px;
      border-radius: 6px;
      margin-right: 20px; }
    .App-program-modal-delete .App-program-modal-delete-footer .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 90px;
      height: 30px;
      border-radius: 6px; }

.App-notifications-content {
  background: white;
  display: flex;
  flex-direction: column; }
  .App-notifications-content > a {
    padding: 20px;
    font-size: 18px;
    word-break: break-all;
    border-bottom: 1px solid rgba(188, 188, 188, 0.5); }
    .App-notifications-content > a:last-child {
      border-bottom: 0; }

.App-approve-main {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .App-approve-main .App-approve-header {
    font-weight: 800;
    height: 55px;
    font-size: 16px;
    padding: 16px 24px;
    border-bottom: 1px solid #e8e8e8; }
  .App-approve-main .App-approve-body {
    font-size: 16px;
    font-weight: 800;
    padding: 24px; }
  .App-approve-main .App-approve-action {
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    border-top: 1px solid #e8e8e8; }
    .App-approve-main .App-approve-action button {
      height: 30px;
      width: 100px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      background: none;
      padding: 0 15px; }
    .App-approve-main .App-approve-action button:first-child {
      margin-right: 15px; }
    .App-approve-main .App-approve-action button:last-child {
      color: #ffffff;
      background-color: #038fde;
      border-color: #038fde;
      margin-left: 8px; }

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #ececec; }

body > .App-page-favorite-item {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px 0 20px;
  border: 1px solid #e0e0e0; }

.App-page {
  width: 100%;
  height: 100%; }

.App-router-content {
  width: 100%;
  height: calc(100% - 70px); }

.App-page-toolbar-box {
  width: 100%;
  height: 60px;
  background-color: #ececec;
  position: relative; }
  .App-page-toolbar-box .App-toolbar-line {
    width: 100%;
    height: 30px;
    background-color: #084698; }
  .App-page-toolbar-box .App-page-toolbar {
    width: 90%;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: absolute;
    left: 5%;
    top: 0;
    z-index: 1; }
  .App-page-toolbar-box .App-toolbar-btn-block button {
    margin-left: 10px !important; }

.App-page-content-box {
  width: 100%;
  height: calc(100% - 60px);
  background-color: #ececec;
  padding: 20px 5%; }
  .App-page-content-box .App-page-content {
    width: 100%;
    max-height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    overflow-y: auto; }

.App-filter-body {
  padding: 20px 20px 0; }

.App-filter-modal {
  min-width: 400px; }

.E-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 33, 39, 0.8);
  z-index: 200; }
  .E-loader-wrapper .E-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.cell {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 5px;
  text-align: center; }

.rc-Calendar-header {
  text-align: center;
  font-weight: bolder;
  background: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center; }

.rc-Month {
  display: inline-block;
  vertical-align: top;
  margin: 20px 0 20px 20px;
  width: calc(100% / 4 - 25px); }

.rc-Month-header {
  text-align: center;
  font-weight: bolder;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white; }

.rc-Month-weekdays-weekday {
  display: inline-block;
  width: calc(100% / 7);
  height: 32px;
  padding: 5px;
  text-align: center;
  font-weight: bolder; }

.rc-Month--current .rc-Month-header {
  color: #337ab7; }

.rc-Week {
  height: 32px; }

.rc-Week-number {
  color: #777777;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 5px;
  text-align: center; }

.rc-Week-days {
  width: 100%;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  display: inline-block; }

.rc-Week:last-child .rc-Week-days {
  border-bottom: 1px solid #eeeeee; }

.rc-Week--current {
  background-color: #5bc0de; }

.rc-Day {
  display: inline-block;
  width: calc(100% / 7);
  height: 32px;
  padding: 5px;
  text-align: center;
  border-right: 1px solid #eeeeee;
  cursor: pointer; }

.rc-Day:hover {
  background-color: #5bc0de; }

.rc-Day--outside {
  cursor: default;
  color: #eeeeee; }

.rc-Day--outside:hover {
  background-color: transparent; }

.rc-Day--event {
  background-color: #eeeeee; }

.rc-Day--current {
  background-color: #337ab7; }

.rc-Day--longEvent {
  background-color: #5cb85c; }

.rc-Day--warning {
  background-color: #f0ad4e; }

.App-closer {
  width: 5px;
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  color: black; }
  .App-closer > i {
    cursor: pointer; }
