// #region Colors

$app-color : #084698;

$black        : #000000;
$white        : #ffffff;
$gray         : #bcbcbc;
$light        : #F6F6F6;
$light-gray   : #ececec;
$green        : #007844;
$light-green  : #0bbf6b;
$red          : #E6374C;
$light-red    : #dc444e;
$yellow       : #e7ad00;
$light-yellow : #fbc43a;
$orange       : #F18407;
$blue         : #084698;

// #endregion

$radius  : 6px;
$padding : 3.2rem;
$shadow  : 0px 3px 6px rgba(0, 0, 0, 0.2);