.App-programs {
  display         : flex;
  flex-direction  : row;
  width           : 90%;
  justify-content : center;
  margin          : auto;

  .App-programs-list {
    
    width         : 30%;
    height        : calc(100vh - 130px); 
    padding       : 60px 40px 0 5px;
    overflow-y    : auto;

    > div {

      padding        : 25px;
      margin-top     : 30px;
      display        : flex;
      cursor         : pointer;
      position       : relative;
      flex-direction : column;
      background     : white;
      box-shadow     : $shadow;

      > .App-programs-actions {

        top      : 25px;
        right    : 25px;
        display  : flex;
        position : absolute;

        > i {
        
          font-size   : 20px;
          margin-left : 10px;
        }
      }

      &.App-chosen-program {

        border-left  : 5px solid $app-color;
        padding-left : 20px;
      }

      > p:first-child {

        // width         : 100%;
        // overflow      : hidden;
        // white-space   : nowrap;
        // text-overflow : ellipsis;
        padding-right : 10px;
        margin-top: 0;
        margin-bottom : 20px;
        font-weight   : 800;
        font-size     : 19px; 
      }

      > p {
        margin-top: 5px;
        word-wrap: break-word;


        > i {
          margin-right: 13px;
        }
      }

      > .user-count {

        color         : #505050;
        border        : 1px solid transparentize(#505050, .5);
        float         : right;
        padding       : 0 5px;
        margin-top    : 20px;
        font-weight   : 300;
        border-radius : 4px;
        align-self   : flex-end;

        > i { margin-right: 10px; }
      }
    }
  }

  .App-programs-info {
    width            : 70%;
    background-color : #f6f6f6;
    padding          : 20px;

    .App-programs-breadcrumbs {

      display : flex;

      > div {

        font-size    : 14px;
        margin-right : 10px;

        &:last-child > span {
          cursor: default;
          color: $app-color;
        }
        > span {

          cursor       : pointer;
          margin-right : 10px;
        }

      }
    }
    
    .App-program-tabs {

      padding     : 25px 0;
      display     : flex;
      align-items : center;

      > h4 {

        cursor         : pointer;
        margin-right   : 40px;
        padding-bottom : 5px;

        &.App-active-tab {

          color         : $app-color;
          border-bottom : 2px solid $app-color;
        }
      }

      .G-btn { margin-left: auto; }

      .icon-waste-can {

        margin-left : 20px;
        font-size   : 22px;
      }
    }

    .App-programs-content {

      box-shadow : $shadow;
      background : white;
      max-height : calc(100vh - 280px);
      overflow-y : auto;
    }

    .App-programs-properties {
      padding         : 25px 0;
      display         : flex;
      flex-direction  : row;
      justify-content : space-between;
    }

    .App-programs-calendar {
      width       : 100%;
      height      : 800px;
      margin-left : 20px;
    }
  }
}

.App-manager-info-main {

  width : 100%;

  &.App-manager-info-main-full {

    .App-manager-info-managers {
      width         : 100% !important;
      margin-top    : 60px !important;
      margin-bottom : 20px;
    }

    .App-manager-info-autocomplete { width: 100% !important; }
  }

  .App-manager-info {
    width    : 50%;
    padding  : 20px;
    position : relative;

    &.App-manager-info-full {

      width   : 100%;
      padding : 0;
    }

    .G-input-box {
      width : 100%;

      input {
        width : 100%;
      }
    }

    .App-manager-info-autocomplete {
      width            : calc(100% - 40px);
      margin-top       : -15px;
      position         : absolute;
      z-index          : 100;
      background-color : #fff;

      ul {
        border        : 1px solid $gray;
        border-radius : $radius;
        width         : 100%;
        padding       : 5px;
      }
    }
  }

  .App-manager-info-managers {
    width      : calc(100% - 40px);
    margin-top : -15px;
    z-index    : 80;

    .App-manager-info-manager-item {
      width   : 100%;
      padding : 5px;

      li {
        border-bottom : 1px solid $gray !important;

      }
    }
  }
}

#margin_top_40 {
  margin-top : -40px;
}

.App-manager-info-autocomplete ul li,
.App-manager-info-manager-item li {
  list-style       : none;
  width            : 100%;
  min-height       : 40px;
  text-indent      : 10px;
  padding          : 10px;
  font-size        : 18px !important;
  font-family      : 'Regular' !important;
  outline          : none !important;
  background-color : #fff;
  display          : flex;
  cursor           : pointer;
  flex-direction   : row;
  position         : relative;

  .App-manager-info-autocomplete-image {
    width         : 35px;
    height        : 35px;
    border-radius : $radius;
  }

  .App-manager-info-delete-icon {
    position  : absolute;
    top       : 50%;
    right     : -35px;
    font-size : 24px;
    transform : translateY(-50%);
  }

  .App-manager-info-autocomplete-texts {
    display        : flex;
    flex-direction : column;
    align-content  : center;

    p {
      font-size : 18px !important;
    }

    span {
      font-size : 10px !important;
    }
  }

  .App-manager-info-responsive-text {

    display     : flex;
    margin-left : auto;
    align-items : center;

    > span {

      color         : #FFC30B;
      font-size     : 30px;
      margin-right  : 10px;
      margin-bottom : 5px;
    }
  }
}

.App-program-modal-add {

  width: 680px;
  padding: 20px;

  &.App-program-activity-modal .App-manager-info-manager-item { width: calc(100% - 40px) !important; }

  .App-program-modal-add-footer {

    display         : flex;
    justify-content : flex-end; 
  
    .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 120px;
      height: 40px;
      border-radius: $radius;
    }
  }
}

.App-program-modal-delete {

  width: 400px;
  padding: 20px;

  .App-program-modal-delete-body {
    // font-size: 19px;
    margin: 10px 0 30px 0;
  }

  .App-program-modal-delete-footer {

    display         : flex;
    justify-content : flex-end; 

    button {
      width: 90px;
      height: 30px;
      border-radius: $radius;
      margin-right: 20px;
    }
  
    .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 90px;
      height: 30px;
      border-radius: $radius;
    }
  }
}
