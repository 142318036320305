.notification {
  background-color : #fff !important;
  border-top-color : $app-color !important;
  color            : #000000 !important;
  padding          : 15px !important;
  box-shadow       : 0px 2px 5px 0px rgba(0,0,0,0.75);
  
  .notification-dismiss {
    background-color: transparent !important;
    color           : #000000 !important;
  }
}

.App-notification-item {
  background-color : #fff;
  color            : #000000 !important;

  h6 {
    color         : #000000;
    text-overflow : ellipsis;
    white-space   : nowrap;
    overflow      : hidden;
  }
}