.App-drop-down-component {
  width    : 100%;
  height   : 100%;
  position : relative;

  &.App-filter {
    .App-drop-down-body {
      left  : auto !important;
      right : 20px;
    }
  }

  .App-drop-down-header {
    height: 100%;
  }

  .App-drop-down-body {
    position         : absolute;
    top              : 50px;
    background-color : $white;
    border-radius    : 4px;
    color            : $black;
    z-index          : 500;
    box-shadow       : $shadow;
  }
}

.App-drop-down-list {
  min-width : 200px;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  padding   : 15px;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  a {
    display: block;

    p {
      display: block !important;
    }

    &:last-child {
      p {
        margin-bottom: 0;
      }
    }

    &.active {
      p {
        font-weight: bold;
      }
    }
  }

  >div.active {
    p {
      font-weight: bold;
    }
  }
  
  p {
    height       : 25px;
    margin-bottom: 8px;
    cursor       : pointer;
    color        : black;
    display      : flex;
    align-items  : center;
  }

  >p {
    display: block;
  }
}
