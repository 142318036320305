
.App-add-page-news-event {
  .line {
    width            : 100%;
    height           : 5px;
    background-color : rgba($gray, 0.5);
  }

  .title {
    font-weight: bold;
  }

  .block {
    padding: 20px;
  }

  .App-manage-event-news-image {
    height: 350px;
    >div {
      height: 100%;
    }

    .App-image-upload-component {
      width: 100%;
    }
  }

  .App-manage-event-news-info,
  .App-manage-event-location-block {
    padding: 20px;
  }
}
