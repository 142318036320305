@import '../variables.scss';

.App-newspaper-add {
  
  width: 680px;
  height: 600px;
  padding: 20px;

  .App-newspaper-add-body {

    height: 470px;
    display: flex;
    // border: 1px solid red;

    .App-newspaper-add-body-image-container {
      width: 35%;
      padding-top: 20px; 
      
      .App-newspaper-add-body-image-box {
        height: 287px;
        
      }

    }

    .App-newspaper-add-body-main {

      width: 65%;
      padding: 15px 0; 

      .App-newspaper-add-date-number {
        width: 415px;
        display: flex;
        justify-content: space-between;

        div:first-child {
          width: 200px !important;
        }

        div:last-child {
          // width: 150px !important;
        }
      }

      .App-newspaper-add-activity {
        display: flex;
        width: 200px;
        height: 80px;
        justify-content: space-between;
        align-items: center;
      }
    }

  }

  .App-newspaper-add-footer {

    display: flex;
    justify-content: flex-end;

    .disabled {
      background-color: rgb(194, 194, 194);
      color: #ffffff;
      border-color: rgb(194, 194, 194);
      cursor: default;
    }
  
    .active {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 120px;
      height: 40px;
      border-radius: $radius;
    }
  }
}