.App-registration-page {
  width      : 100%;
  height     : 100vh;

  .left-box {
    width       : 50%;
    height      : 100%;
    background  : url('../images/background.jpg') no-repeat center/cover;
    display     : flex;
    align-items : flex-end;
    position    : relative;

    img {
      height   : 70px;
      position : absolute;
      top      : 10%;
      left     : 10%;
    }
  }

  .right-box {
    width      : 50%;
    min-width  : 800px;
    height     : 100%;
    overflow-y : auto;

    .form {
      width   : 100%;
      padding : 5% 10%;
    }
  }
}

.App-choose-default-image {
  min-width  : 300px;
  min-height : 250px;
  padding    : 8%;

  .images {
    div {
      width         : 150px;
      height        : 150px;
      border        : 2px solid rgba(128, 128, 128, 0.3);
      border-radius : 6px;
      cursor        : pointer;

      &.active {
        border-color: $app-color;
      }
    }
  }
}