// Invite

.App-invite-user {
  min-width : 400px;
  padding   : 20px 30px;

  .App-add-btn {
    color: $orange;
  }

  .G-btn.App-btn {
    width: 200px;
  }

  .App-invite-user-item {
    position: relative;

    i {
      position  : absolute;
      right     : -20px;
      top       : 50%;
      transform : translateY(-50%);
      font-size : 14px; 

      &.G-cursor {
        &:hover {
          color: $red;
        }
      }
    }
  }
}

// User item 

// Table

.App-user-item-start-end {
  width: 200px;
}

.App-user-item {
  width: calc((100% - 400px) / 3);
}

.App-user-item-table {
  >div {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
} 
