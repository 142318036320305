.App-language-selector {

  p {
    color   : $black;
    opacity : .6;
    cursor  : pointer;

    &.active {
      opacity: 1;
    }
  }
}

/* Upload photo */

.App-image-upload-component {
  width         : calc(100% - 20px);
  height        : calc(100% - 20px);
  max-height    : calc(100% - 20px);
  margin-bottom : 20px;

  >input {
    display: none;
  }

  &.G-error {
    .App-image-upload>label {
      border-color: $red !important;
    }
  }
  
  &.App-multi-image-upload {
    width   : 100%;

    .App-image-upload {
      width  : calc((100% - 40px) / 3);
      height : 250px;

      &:nth-child(3n + 1) {
        margin-right: 0 !important;
      }

      .App-image {
        height : 250px;
      }

      .App-uploaded-image {
        padding: 0;

        &.image {
          height: 250px;
        } 
      }
    }
  }
}

.App-image-upload {
  width : 100%;
  height: 100%;
}

.App-image-upload>label,
.App-uploaded-image {
  width            : 100%;
  height           : 100%;
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  justify-content  : center;
  border           : 1px dashed #3A3A3A;
  border-radius    : 18px ;
  cursor           : pointer;
  background-color : #EBEBEB;
}

.App-image-upload>label>i {
  font-size     : 30px;
  margin-bottom : 10px;
  color         : #707070;
}

.App-image-upload>label>p {
  color      : #707070;
  text-align : center;

  b {
    text-decoration: underline;
  }
}

.App-uploaded-image {
  position: relative;
  width   : 100%;
  height  : 100%;
  overflow: hidden;
}

.App-uploaded-image>.image {
  width              : 100%;
  height             : 100%;
  background-size    : cover;
  background-position: center;
  background-repeat  : no-repeat;
}

.App-uploaded-image>.opacity {
  width           : 100%;
  height          : 100%;
  opacity         : 0;
  transition      : all .3s;
  position        : absolute;
  top             : 0;
  left            : 0;
  display         : flex;
  align-items     : center;
  justify-content : center;
  background-color: rgba(0, 0, 0, 0.3);
}

.App-uploaded-image>.opacity>h4 {
  color: #fff;
}

.App-uploaded-image:hover>.opacity {
  opacity: 1;
}

/* Upload photo end */

/* Upload file */

.App-file-upload-component {
  .input {
    width         : 100%;
    min-height    : 40px;
    border        : 1px solid #bcbcbc;
    border-radius : 6px;
    text-indent   : 10px;
    padding-right : 10px;
    overflow-x    : auto;
  }

  .App-file-upload,
  label {
    position: relative;

    .icon-close {
      position : absolute;
      right    : 10px;

      &:hover {
        color: $red;
      }
    }

    p {
      width         : 85%;
      white-space   : nowrap;
      text-overflow : ellipsis;
      overflow      : hidden;
    }
  }
}

/* Upload file */


/* Modal */

.App-modal {
  width            : 100%;
  height           : 100vh;
  position         : fixed;
  top              : 0;
  left             : 0;
  background-color : rgba(0, 0, 0, .5);
  z-index          : 1000;
}

.App-modal-content {
  max-width       : 90vw;
  max-height      : 90vh;
  background-color: $white;
  position        : absolute;
  top             : 50%;
  left            : 50%;
  transform       : translate(-50%, -50%);
  box-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow        : auto;
  border-radius   : 4px; 
}

/* Modal end */

/* Table start */

.App-table-image {
  width        : 45px;
  height       : 45px;
  border-radius: 4px;
}

.App-table-toolbar {
  width: 100%;
}

.App-table-toolbar-title {
  span {
    color: rgba(0, 0, 0, .5);
  }
}

.App-table-toolbar-viewmode {

  font-size   : 20px;
  margin-left : 10px;
  font-weight : 700;

  &.App-active-viewmode { color: $app-color; }

  &:first-of-type { margin-left: 20px; }
}

.App-table-status-item {
  display     : flex;
  align-items : center;

  span {
    display       : block;
    width         : 8px;
    height        : 8px;
    border-radius : 50%;
    margin-right  : 10px;
  }
}

/* Table end */

/* Date picker */

.App-date-picker {

  &.G-error {
    .MuiInputBase-root {
      border-color: $red;
    }  
  }

  .MuiInputBase-root {
    width         : 100%;
    height        : auto;
    min-height    : 40px;
    border        : 1px solid $gray;
    border-radius : $radius;
    text-indent   : 10px;
    padding       : 0 10px 0 0;
    overflow      : hidden;

    &::after,
    &::before {
      display: none;
    }

    input {
      height  : 38px;
      min-height: 38px;
      border  : none !important;
      padding : 0;
      cursor  : pointer;
    }
  }
}

/* Date picker end */