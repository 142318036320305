/* You can add HTML default tag styles */

@font-face {
  font-family : 'Regular';
  src         : url('../fonts/Roboto-Regular.ttf');
}

* {
  margin      : 0;
  padding     : 0;
  box-sizing  : border-box;
  font-family : 'Regular';
  outline     : none;
}

// RC Datepicker

[class^="icon-rc-datepicker"], [class*=" icon-rc-datepicker"] { font-family: 'rc-datepicker' !important; }

.react-datepicker-input {
  border: 1px solid #cdcdcd !important;
  height: 45px !important;
  border-radius: 6px !important;

  > input {

    color        : black !important;
    font-weight  : 300 !important;
    padding-left : 3px !important;
  }
}

// RC Datepicker end

// React Tags

.ReactTags__selected {

  width         : 100%;
  border        : 1px solid #bcbcbc;
  padding       : 10px 10px 0;
  display       : flex;
  min-height    : 40px;
  text-indent   : 10px;
  align-items   : center;
  flex-wrap     : wrap;
  border-radius : 6px;

  > span {

    float         : left;
    border        : 1px solid #D6D6D6;
    margin        : 0 10px 10px 0;
    padding       : 0 5px;
    display       : flex;
    align-items   : center;
    word-break    : break-all; 
    border-radius : 6px;
  }

  > .ReactTags__tagInput {

    flex      : 1;
    min-width : 250px;

    > input {

      float         : left;
      width         : 100%;
      height        : 20px;
      border        : 0;
      padding       : 0;
      min-height    : auto;
      text-indent   : 0;
      margin-bottom : 10px;
    }
  }
}

// React Tags end

[id^=contextMenu] {
  position: fixed !important;
  z-index: 999;
}

.rbc-row-content { z-index: initial; }
.rbc-overlay { transform: translateY(-50px); }

html, body {
  font-size        : 18px;
  color            : $black;
  background-color : $light;  
  overflow-y       : hidden;
  min-width        : 1024px;
}

textarea, input, button, pre {
  font-size   : 18px !important;
  font-family : 'Regular' !important;
  outline     : none !important;
}

input::placeholder, textarea::placeholder {
  // $light-gray
  color: #cccccc
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin     : 0;
  appearance : none;
}

input[type=file] {
  display: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

i[class*=icon] {
  cursor : pointer;
}

textarea { resize : none; }
a, button { cursor : pointer; }

a {
  text-decoration : none;
  cursor          : pointer;
  color           : #000;
}

button {
  border           : 0;
  background-color : transparent;
}

h1, h2, h3 {
  letter-spacing : 1px;
}

h4, h5, h6 {
  letter-spacing : 1px;
  font-weight    : 100;
}

h1 { font-size : 28px; }
h2 { font-size : 26px; }
h3 { font-size : 24px; }
h4 { font-size : 22px; }
h5 { font-size : 20px; }
h6 { font-size : 18px; }
p  { font-size : 16px; }

*::-webkit-scrollbar-track, *::-ms-autohiding-scrollbar {
  border-radius    :  2px;
  background-color :  #F5F5F5;
}

*::-webkit-scrollbar {
  width            : 4px;
  height           : 8px;
  background-color : #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius      : 4px;
  background-color   : grey;
}


.rbc-month-row {
  height     : 80px;
  flex-basis : initial;
}