.App-sign-in-page {
  width  : 100%;
  height : 100vh;

  .left-box {
    width       : 100%;
    width       : 100%;
    background  : url('../images/background.jpg') no-repeat center/cover;
    display     : flex;
    align-items : flex-end;
    position    : relative;

    img {
      height   : 70px;
      position : absolute;
      top      : 10%;
      left     : 10%;
    }
  }

  .right-box {
    width: 800px;

    .form {
      width   : 100%;
      padding : 0 15%;
    }
  }
}