header {
  width      : 100%;
  height     : 70px;
  padding    : 0 5%;
  background : linear-gradient(180deg, rgba(0,0,104,1) 0%, rgba(8,70,152,1) 100%);
  color      : $white;
  box-shadow : $shadow;

  .App-header {
    height: 70px;
  }

  .App-header-notification {

    color        : white;
    position     : relative;
    font-size    : 22px;
    margin-right : 30px;

    > span {

      right         : 0;
      width         : 12px;
      height        : 12px;
      position      : absolute;
      background    : red; 
      border-radius : 50%;
    }
  }

  .logo {
    img {
      height: 45px;
    }
  }

  .left-block {

    > div {
      
      position : relative;

      > .App-notifications-badge {

        top             : -2px;
        right           : -8px;
        width           : 15px;
        height          : 15px;
        padding-top     : 1px;
        padding-left    : 1px;
        display         : flex;
        align-items     : center;
        justify-content : center;
        position        : absolute;
        background      : red;
        border-radius   : 50%;
        font-size       : 12px;
      }
    }

    a {
      color: inherit;
      opacity: .7;

      &:hover, &.active {
        opacity: 1;
      }
    }
  }
}

.App-notification-list {
  width        : 500px;
  margin-right : 20px !important;

  >div {
    padding: 20px;
  }

  .App-notification-header {
    border-bottom: 1px solid $light-gray;
  }

  .App-notification-content {

  }

  .App-notification-footer {
    border-top: 1px solid $light-gray;
    button {
      &:hover {
        color: $app-color;
      }
    }
  }
}
