.App-page-favorite-box {
  width  : 90vw;
  margin : 20px 5%; 

  .App-page-favorite-content {
    width            : 100%;    
    max-height       : 100%;
    background-color : $white;
    box-shadow       : $shadow;
    overflow-y       : auto;
    // padding: 0 50px;

    .App-page-favorite-header {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 0 20px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);

      > div {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.3125rem;
      }

    }

    .App-page-favorite-item {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px 0 20px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

  }
}

.App-book-choose-categories {

  width   : 500px;
  padding : 30px; 

  > div {

    padding    : 10px 0;
    font-size  : 18px;
    word-break : break-all;

    > span { margin-left: 10px; }
  }
}

.App-book-categories-input {
  word-break   : break-all;
  text-indent  : 0 !important;
  display      : flex;
  align-items  : center;
  padding-left : 10px;
}

.App-manage-book-image {
  padding: 20px;

  .App-image-upload-component {
    max-height: 70%;
  }
}

.App-modify-book-category {
  display: flex;
  height: 250px;
  width: 500px;

  .App-modify-book-category-image {
    width: 40%;
  }

  > div:last-child {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: flex-start;
  }

}