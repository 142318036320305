@import '~react-table/react-table.css';
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import "./icons";

@import "./variables";

@import "./normalize";
@import "./overwrite";
@import "./global";

// Components start

@import "./components/index";
@import "./components/header";
@import "./components/notification";
@import "./components/drop-down";

// Components end

// Pages start

@import "./views/sign-in";
@import "./views/registration";
@import "./views/user";
@import "./views/media";
@import "./views/event-news";
@import "./views/builder";
@import "./views/newspaper";
@import "./views/books";
@import "./views/programs";
@import "./views/notifications";
@import "./views/approvement.scss";

// Pages end

#root {
  width            : 100%;
  height           : 100vh;
  overflow         : hidden;
  background-color : #ececec;
}

body {
  > .App-page-favorite-item {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px 0 20px;
    border: 1px solid rgba(224, 224, 224, 1);
  }
}

.App-page {
  width    : 100%;
  height   : 100%;
}

.App-router-content {
  width  : 100%;
  height : calc(100% - 70px);
}

.App-page-toolbar-box {
  width            : 100%;
  height           : 60px;
  background-color : $light-gray;
  position         : relative;
  
  .App-toolbar-line {
    width            : 100%;
    height           : 30px;
    background-color : $app-color;
  }
  
  .App-page-toolbar {
    width            : 90%;
    height           : 60px;
    background-color : $white;
    box-shadow       : $shadow;
    border-radius    : 4px;
    position         : absolute;
    left             : 5%;
    top              : 0;
    z-index          : 1;
  }

  .App-toolbar-btn-block {
    button {
      margin-left: 10px !important;
    }
  }
}

.App-page-content-box {
  width            : 100%;
  height           : calc(100% - 60px);
  background-color : $light-gray;
  padding          : 20px 5%;

  .App-page-content {
    width            : 100%;
    max-height       : 100%;
    background-color : $white;
    box-shadow       : $shadow;
    overflow-y       : auto;
  }
}

// Filters

.App-filter-body {
  padding: 20px 20px 0;
}

.App-filter-modal {
  min-width: 400px;
}

// Filter end 

// .App-loader {}

.E-loader-wrapper {
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  background-color: rgba(32, 33, 39, 0.8);
  z-index         : 200;

  .E-loader {
    position: absolute;
    top     : 50%;
    left    : 50%;
    transform: translate(-50%,-50%)
  }
}


// .react-datepicker .react-datepicker-container .react-datepicker-top {  
//   background  : #2C2D32 !important;
//   color       : #fff;
//   border-top  : 1px solid #fff !important;
//   border-left : 1px solid #fff !important;
//   border-right: 1px solid #fff !important;
//   border      : 0 !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current {
//   background-color: #2C2D32 !important;
//   color           : #FFF !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker {
//   background-color: #2C2D32 !important;
//   color           : #FFF !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker:not(:last-child) {
//   border-right-color: #202127 !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row:not(:last-child) {
//   border-bottom-color: #202127 !important;  
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body {
//   border-color       : #2C2D32 !important;
//   border-left-color  : #fff !important;
//   border-right-color : #fff !important;
//   border-bottom-color: #fff !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected {
//   color       : #fff !important;
//   background  : $app-color !important;
//   margin      : 0 !important;
//   border      : 1px solid transparent !important;
//   border-right: 1px solid #202127 !important;
// }

// .react-flex-view.flex-column.react-datepicker-top::after, .react-flex-view.flex-column.react-datepicker-top::before  {
//   display: none !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected>span {
//   width           : 100%;
//   height          : 100%;
//   color           : #fff !important;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
// }

// .E-datepicker-component {
//   width  : 100%;
//   height : 40px;
//   display: inline-block;
//   margin : 10px 0;
// }

// .react-datepicker-component .react-datepicker {
//   margin: 0 !important
// }

// .react-flex-view.react-datepicker-row.year >div {
//   border-color: #2C2D32 !important;
// }

// .react-datepicker-component .react-datepicker-input, .react-datepicker-component .react-datepicker-input:hover {
//   background: transparent !important;
// }

// .react-datepicker-input > input::placeholder {
//   color: gray !important;
// }
// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker:not(:last-child) {
//   border-right-color: transparent !important;
// }

// .react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row:not(:last-child) {
//   border-bottom-color: #2C2D32 !important;
// }

// .react-flex-view.display, .react-flex-view.week-days {
//   height      : 50px !important;
//   border-right: 1px solid;
//   border-left : 1px solid;
// }

// .react-flex-view.flex-column.react-datepicker-body {
//   background-color: $app-color;
// }

// .react-flex-view.align-content-center.justify-content-center.react-datepicker-picker.day> span {
//   color: gray !important;
// }

// .react-flex-view.align-content-center.justify-content-center.react-datepicker-picker.day.current> span {
//   color: #fff !important;
// }

// .react-flex-view.align-content-center.justify-content-center.react-datepicker-picker.day.current.selected> span {
//   color: #fff !important;
// }

.cell {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 5px;
  text-align: center;
}
.rc-Calendar-header {
  text-align: center;
  font-weight: bolder;
  background: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.rc-Month {
  display: inline-block;
  vertical-align: top;
  margin: 20px 0 20px 20px;
  width: calc(100% / 4 - 25px);
}
.rc-Month-header {
  text-align: center;
  font-weight: bolder;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.rc-Month-weekdays-weekday {
  display: inline-block;
  width: calc(100% / 7);
  height: 32px;
  padding: 5px;
  text-align: center;
  font-weight: bolder;
}
.rc-Month--current .rc-Month-header {
  color: #337ab7;
}
.rc-Week {
  height: 32px;
}
.rc-Week-number {
  color: #777777;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 5px;
  text-align: center;
}
.rc-Week-days {
  width: 100%;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  display: inline-block;
}
.rc-Week:last-child .rc-Week-days {
  border-bottom: 1px solid #eeeeee;
}
.rc-Week--current {
  background-color: #5bc0de;
}
.rc-Day {
  display: inline-block;
  width: calc(100% / 7);
  height: 32px;
  padding: 5px;
  text-align: center;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
}
.rc-Day:hover {
  background-color: #5bc0de;
}
.rc-Day--outside {
  cursor: default;
  color: #eeeeee;
}
.rc-Day--outside:hover {
  background-color: transparent;
}
.rc-Day--event {
  background-color: #eeeeee;
}
.rc-Day--current {
  background-color: #337ab7;
}
.rc-Day--longEvent {
  background-color: #5cb85c;
}
.rc-Day--warning {
  background-color: #f0ad4e;
}

.App-closer {
  width: 5px;
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  color: black;
  > i {
    cursor: pointer;
  }
}