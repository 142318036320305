// TextField

.MuiFormLabel-focused-17 {
  color: $black !important;
}

.MuiOutlinedInput-focused-24 {
  fieldset {
    border-color: $black !important;
  }
}

// TextField end

// Buttons 

.MuiButton-containedPrimary-137,
.MuiSwitch-bar-38 {
  background-color: $app-color !important;
}

[class*="MuiCheckbox-checked"],
[class*="MuiRadio-checked"],
.MuiCheckbox-colorSecondary-124.MuiCheckbox-checked-120,
.MuiCheckbox-colorSecondary-101.MuiCheckbox-checked-97,
.MuiCheckbox-colorSecondary-98.MuiCheckbox-checked-94,
.MuiSwitch-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiSwitch-colorSecondary-36.MuiSwitch-checked-34 {
  color: $app-color !important;

  &:hover {
    background-color: rgba($color: $app-color, $alpha: .2) !important;
  }
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba($color: $app-color, $alpha: .2) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: rgba($color: $app-color, $alpha: .5) !important;
}

// Buttons end

// Date picker

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.MuiPickersModal-dialogRootWider {
  min-width: 500px !important;
}

.MuiPickersToolbarButton-toolbarBtn {
  margin-right: 20px !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClockPointer-pointer {
  background-color: $app-color !important;
}

.MuiButton-textPrimary {
  color: $app-color !important;
}

.MuiPickersClockPointer-thumb {
  border-color: $app-color !important;
}


// Date picker end

// Editor

.rdw-editor-wrapper {
  width         : 100%;
  min-height    : 40px;
  border        : 1px solid $gray;
  border-radius : $radius;
  overflow-x    : auto;

  .rdw-editor-toolbar {
    padding          : 5px 0;
    background-color : $light-gray;

    .rdw-option-wrapper,
    .rdw-dropdown-wrapper {
      background   : $light-gray;
      border-color : $light-gray;

      &.rdw-option-active, &:hover {
        border-color : #BFBDBD !important;
        box-shadow   : none;
      }
    }
  }

  .DraftEditor-editorContainer {
    padding: 0 10px;
  }

  .rdw-text-align-wrapper,
  .rdw-inline-wrapper {
    margin-bottom: 0px;
  }
}

// Editor end
