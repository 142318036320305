.App-modify-media {
  min-width : 400px;
  padding   : 20px 30px;

  .App-add-btn {
    color: $orange;
  }

  .G-btn.App-btn {
    width: 200px;
  }

  .App-modify-media-content {
    
    position  : relative;
    flex-wrap : wrap;
  }
}

.App-media-items-view-content {
  
  display    : flex;
  padding    : 10px;
  flex-wrap  : wrap;
}

.App-media-list-item {

  flex      : 1;
  margin    : 10px;
  height    : 300px;
  cursor    : pointer;
  min-width : 300px;
}

.App-media-details {

  width  : calc(100vw - 150px);
  height : calc(100vh - 150px);
}