
// #region Flex

.G-flex {
  display : flex;
}

.G-center {
  justify-content : center;
  align-items     : center;
}

.G-justify-center {
  justify-content : center;
}

.G-justify-between {
  justify-content : space-between;
}

.G-justify-around {
  justify-content : space-around;
}

.G-justify-end {
  justify-content : flex-end;
}

.G-align-content-start {
  align-content: flex-start;
}

.G-align-center {
  align-items : center;
}

.G-align-start {
  align-items : flex-start;
}

.G-align-end {
  align-items : flex-end;
}

.G-flex-column {
  flex-direction : column;
}

.G-flex-wrap {
  flex-wrap : wrap;
}

// #endregion

// #region Margins

.G-m-1 { margin: 10px; }
.G-m-2 { margin: 20px; }
.G-m-3 { margin: 30px; }
.G-m-4 { margin: 40px; }
.G-m-5 { margin: 50px; }
.G-m-6 { margin: 60px; }

.G-mb-1 { margin-bottom: 10px; }
.G-mb-2 { margin-bottom: 20px; }
.G-mb-3 { margin-bottom: 30px; }
.G-mb-4 { margin-bottom: 40px; }
.G-mb-5 { margin-bottom: 50px; }
.G-mb-6 { margin-bottom: 60px; }

.G-mt-1 { margin-top: 10px; }
.G-mt-2 { margin-top: 20px; }
.G-mt-3 { margin-top: 30px; }
.G-mt-4 { margin-top: 40px; }
.G-mt-5 { margin-top: 50px; }
.G-mt-6 { margin-top: 60px; }

.G-mr-1 { margin-right: 10px; }
.G-mr-2 { margin-right: 20px; }
.G-mr-3 { margin-right: 30px; }
.G-mr-4 { margin-right: 40px; }
.G-mr-5 { margin-right: 50px; }
.G-mr-6 { margin-right: 60px; }

.G-ml-1 { margin-left: 10px; }
.G-ml-2 { margin-left: 20px; }
.G-ml-3 { margin-left: 30px; }
.G-ml-4 { margin-left: 40px; }
.G-ml-5 { margin-left: 50px; }
.G-ml-6 { margin-left: 60px; }

// #endregion

// #region Padding

.G-p-1 { padding: 10px; }
.G-p-2 { padding: 20px; }
.G-p-3 { padding: 30px; }
.G-p-4 { padding: 40px; }
.G-p-5 { padding: 50px; }
.G-p-6 { padding: 60px; }

.G-pb-1 { padding-bottom: 10px; }
.G-pb-2 { padding-bottom: 20px; }
.G-pb-3 { padding-bottom: 30px; }
.G-pb-4 { padding-bottom: 40px; }
.G-pb-5 { padding-bottom: 50px; }
.G-pb-6 { padding-bottom: 60px; }

.G-pt-1 { padding-top: 10px; }
.G-pt-2 { padding-top: 20px; }
.G-pt-3 { padding-top: 30px; }
.G-pt-4 { padding-top: 40px; }
.G-pt-5 { padding-top: 50px; }
.G-pt-6 { padding-top: 60px; }

.G-pr-1 { padding-right: 10px; }
.G-pr-2 { padding-right: 20px; }
.G-pr-3 { padding-right: 30px; }
.G-pr-4 { padding-right: 40px; }
.G-pr-5 { padding-right: 50px; }
.G-pr-6 { padding-right: 60px; }

.G-pl-1 { padding-left: 10px; }
.G-pl-2 { padding-left: 20px; }
.G-pl-3 { padding-left: 30px; }
.G-pl-4 { padding-left: 40px; }
.G-pl-5 { padding-left: 50px; }
.G-pl-6 { padding-left: 60px; }

// #endregion

// #region Width

.G-w-10 { width: 10%; }
.G-w-20 { width: 20%; }
.G-w-25 { width: 25%; }
.G-w-30 { width: 30%; }
.G-w-35 { width: 35%; }
.G-w-40 { width: 40%; }
.G-w-45 { width: 45%; }
.G-w-50 { width: 50%; }
.G-w-55 { width: 55%; }
.G-w-60 { width: 60%; }
.G-w-65 { width: 65%; }
.G-w-70 { width: 70%; }
.G-w-75 { width: 75%; }
.G-w-80 { width: 80%; }

.G-w-f { width: 100%; }
.G-w-3 { width: calc(100 / 3) }


// #endregion


// #region Global classes

.G-details-title-label {
  font-weight   : 700;
  margin-bottom : 10px;
}

.G-details-upload-label {
  padding-left: 10px;
  margin-bottom: 10px;

  > b { font-weight: 700; }
}

.G-break-word { word-wrap: break-word; }

.G-capitalize {
  text-transform : capitalize;
}

.G-error { border-color: $red !important; }

.G-bold { font-weight: bold; };

.G-line {
  width            : 100%;
  height           : 1px;
  background-color : $light-gray;
  margin           : 5px 0 20px;
}

.G-cursor { cursor: pointer; }

.G-bg-image {
  background-size     : cover;
  background-position : center;
  background-repeat   : no-repeat;
}

.G-btn {
  min-width     : 140px !important;
  border        : 1px solid $gray;
  border-radius : $radius;
  padding       : 6px;

  &.App-btn {
    width            : 100%;
    background-color : $app-color;
    border-color     : $app-color;
    color            : $white;
  }

  &.App-add-btn, &.App-save-btn {
    background-color : $orange;
    color            : $white;
    border-color     : $orange;
  }

  &.App-add-btn-2 {
    color  : $orange;
    border : 0;
  }

  &.App-filter-btn {
    height          : 100%;
    display         : flex;
    align-items     : center;
    justify-content : center;

    i {
      font-size    : 14px;
      margin-right : 10px;
    }
  }
}

.G-text { color: rgba(0, 0, 0, 0.7); }
.G-page-active-title { color: $app-color }
.G-show { display: block; }
.G-hide {
  display: none;

  &.G-show {
    display: block !important;
  }
}

// Input styles

.G-half-input-box {
  .G-input-box {
    width: calc(50% - 10px);
  } 
}

.G-input-box {
  width         : 100%;
  margin-bottom : 20px;

  .G-input-password-box, .G-input-delete-box {
    position: relative;

    button, .icon-close {
      position  : absolute;
      top       : 50%;
      right     : 10px;
      transform : translateY(-50%);
      color     : $app-color;
      font-size : 10px;
    }

    .icon-close {
      font-size : 16px !important;
      color     : black;

      &:hover {
        color: $red;
      }
    }
  }

  p {
    span { color: $red; }
  }

  .G-input, .input, input, textarea, .App-drop-down-header {
    width         : 100%;
    min-height    : 40px;
    border        : 1px solid $gray;
    border-radius : $radius;
    text-indent   : 10px;
    padding-right : 10px;
    overflow-x    : auto;
  }

  .App-drop-down-component {

    .App-drop-down-header {

      cursor      : pointer;
      display     : flex;
      align-items : center;
    }

    .App-drop-down-body {

      top   : 40px;
      width : 100%;

      > .App-drop-down-list > div {

        cursor  : pointer;
        padding : 5px 0;
      }
    }
  }

  textarea {
    padding     : 10px;
    text-indent : 0;
  }
}

.G-input-box-desc {
  > div {
    min-height: 700px;
  }
}

.menuItem:hover {
  font-weight: 800;
}

// Input styles end

.ql-editor{
  p > span {
    color: black;
  }
}

.ql-container.ql-snow {
  min-height: 650px;
}
// #endregion
