@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?3koptb');
  src:  url('../fonts/icomoon.eot?3koptb#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?3koptb') format('truetype'),
    url('../fonts/icomoon.woff?3koptb') format('woff'),
    url('../fonts/icomoon.svg?3koptb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_back:before {
  content: "\e900";
}
.icon-arrow_down:before {
  content: "\e901";
}
.icon-arrow_next:before {
  content: "\e902";
}
.icon-arrow_top:before {
  content: "\e903";
}
.icon-attach:before {
  content: "\e904";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-eye:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
.icon-help:before {
  content: "\e90a";
}
.icon-home:before {
  content: "\e90b";
}
.icon-item_1:before {
  content: "\e90c";
}
.icon-item_2:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e90f";
}
.icon-notification:before {
  content: "\e910";
}
.icon-office-paper-printer:before {
  content: "\e911";
}
.icon-plus-addition-sign-circle:before {
  content: "\e912";
}
.icon-refresh-page-arrow:before {
  content: "\e913";
}
.icon-saved:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
.icon-settings:before {
  content: "\e916";
}
.icon-sort:before {
  content: "\e917";
}
.icon-star:before {
  content: "\e918";
}
.icon-upload:before {
  content: "\e919";
}
.icon-waste-can:before {
  content: "\e91a";
}
.icon-wish_list:before {
  content: "\e91b";
}
.icon-writing-notepad:before {
  content: "\e91c";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-attachment:before {
  content: "\e9cd";
}
