.App-section-list {
  width      : 100%;
  max-height : 100%;
  overflow-y : auto;
  padding    : 0 5px 0;

  .App-section-item {
    width            : 100%;
    min-height       : 250px;
    background-color : $white;
    box-shadow       : $shadow;
    position         : relative;
    margin-bottom    : 20px;

    .header {
      width: 100%;
    }

    .content {
      min-height : 200px;
      .G-bg-image {
        width: 30%;
      }

      .text {
        width: 65%;
      }
    }
  }
}

.App-radio-btn-box {
  .G-flex {
    display: flex !important;
    flex-direction: row;
  }
}

.App-add-section {
  width     : 50vw;
  min-width : 300px;
  padding   : 20px;

  &.App-add-section-auto-width { width: auto; }

  .image-upload-box {
    width: 100%;
    .image-upload {
      width  : 100%;
      height : 200px;

      .App-image-upload-component {
        width: 100% !important;
      }
    }
  }

  .form {
    width     : 100%;
    min-width : 500px;
  }
}

.App-page-calculator-box {
  width  : 100%;
  height : 60px;
  margin : 20px 5%;  
  
  .App-page-calculator-header {

    display: flex;
    justify-content: space-between;
    max-width: 90vw;

    .App-page-calculator-header-box {
      display: flex;
      div {
        width: 500px;
      }

    }
  
    .save {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      height: 40px;
      margin: 22px 0 20px 50px;
    }
  
    .disabled {
      background-color: rgb(194, 194, 194);
      color: #ffffff;
      border-color: rgb(194, 194, 194);
      height: 40px;
      margin: 22px 0 20px 50px;
      cursor: default;
    }
  
    .download {
      background-color: #F18407;
      color: #ffffff;
      border-color: #F18407;
      width: 200px;
      height: 40px;
      margin: 22px 0 20px 150px;
    }
  }

  .App-page-calculator-body {
    max-width: 90vw;
    max-height: 70vh;
    height: 70vh;
    overflow: auto;
    // border: 1px solid red;
    background-color: $white;
    box-shadow: $shadow;
    padding: 5px 20px 25px 5px;
  }

  .App-calculator-body-row {
    display: flex;
    flex-direction: row;

    .App-calculator-body-item {
      min-width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $light-gray;
      overflow: hidden;
    }
    
    .main {
      border: none;
      font-weight: 800;
    }

    .short {
      min-width: 80px ;
    }

  }
}